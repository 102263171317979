<template>
  <div class="pb-5">
    <h5 class="primary--text fw-700 font-14px text-center mb-6">
      ディナー売上について
    </h5>
    <div class="font-12px text-333 line-height-15">
      <p>
        登録時に入力した値を用いて、以下のように算出された値が、ディナー売上として登録されています。
      </p>
      <div class="light-yellow mx-3 py-2 px-1 my-5 text-center">
        ( 当日売上 ) ー ( ランチ売上 ) ＝ ( ディナー売上 )
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DinnerSalesModal",
};
</script>
